<template>
  <div class="mod-green_application_information}">
    <div>
      <!-- <div>用户基本信息</div> -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>{{ title }}</span>
          <span v-if="showHeaderButton">
            <el-button style="float: right; padding: 3px 3px" type="text" v-if="$hasPermission('miaoins')" @click="editorApplicationInformation()">编辑</el-button>
            <el-button style="float: right; padding: 3px 3px" type="text" v-if="$hasPermission('miaoins')" @click="saveApplicationInformation()">保存</el-button>
          </span>
        </div>
        <div class="basic_information">
          <el-form :inline="true" :disabled="disabled" :model="exclusiveCareForm" ref="exclusiveCareForm" :rules="dataRule" class="demo-form-inline">
            <!-- <el-form-item label="意向日期" prop="applyHospitalStartDate">
              <el-date-picker value-format="yyyy-MM-dd" v-model="exclusiveCareForm.applyHospitalStartDate" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="地区" prop="selectedOptions">
              <el-cascader v-model="exclusiveCareForm.selectedOptions" :options="options" :props="{ expandTrigger: 'hover' }" clearable @change="handleChange"></el-cascader>
            </el-form-item> -->
            <div class="tipsTit">就诊医院信息</div>
            <el-form-item label="医院名称" prop="hospitalName">
              <el-input v-model="exclusiveCareForm.hospitalName" placeholder="医院名称"></el-input>
            </el-form-item>
            <el-form-item label="科室名称">
              <el-input v-model="exclusiveCareForm.departmentName" placeholder="科室名称"></el-input>
            </el-form-item>
            <el-form-item label="主治医师">
              <el-input v-model="exclusiveCareForm.doctorName" placeholder="主治医师"></el-input>
            </el-form-item>
            <el-form-item label="检测项目">
            <el-checkbox-group v-model="examType">
              <el-checkbox label="examBlood">血液</el-checkbox>
              <el-checkbox label="examTissue">组织液</el-checkbox>
            </el-checkbox-group>
            </el-form-item>
            <div class="tipsTit">血液样本信息</div>
            <el-form-item label="血液样本">
            <el-radio-group v-model="exclusiveCareForm.bloodFlag">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">有</el-radio>
            </el-radio-group>
          </el-form-item>
            <el-form-item label="血液剂量">
              <el-input v-model="exclusiveCareForm.bloodMl" placeholder="血液剂量(ml)"></el-input>
            </el-form-item>
            <el-form-item label="血液管数">
              <el-input v-model="exclusiveCareForm.bloodTube" placeholder="血液管数"></el-input>
            </el-form-item>
            <el-form-item label="采集时间" prop="bloodTime">
              <el-date-picker value-format="yyyy-MM-dd" v-model="exclusiveCareForm.bloodTime" type="date" placeholder="血液采集时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="胸腹水">
            <el-checkbox-group v-model="hydrothoraxAscitesType">
              <el-checkbox label="hydrothoraxFlag" >胸水</el-checkbox>
              <el-checkbox label="ascitesFlag">腹水</el-checkbox>
            </el-checkbox-group>
            </el-form-item>
            <el-form-item label="胸腹水剂量">
              <el-input v-model="exclusiveCareForm.hydrothoraxAscitesMl" placeholder="胸腹水剂量(ml)"></el-input>
            </el-form-item>
            <el-form-item label="胸腹水管数">
              <el-input v-model="exclusiveCareForm.hydrothoraxAscitesTube" placeholder="胸腹水管数"></el-input>
            </el-form-item>
            <el-form-item label="采集时间" prop="hydrothoraxAscitesTime">
              <el-date-picker value-format="yyyy-MM-dd" v-model="exclusiveCareForm.hydrothoraxAscitesTime" type="date" placeholder="胸腹水采集时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="细胞悬液">
            <el-radio-group v-model="exclusiveCareForm.cellSuspensionFlag">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">有</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="细胞悬液剂量">
              <el-input v-model="exclusiveCareForm.cellSuspensionMl" placeholder="细胞悬液剂量(ml)"></el-input>
            </el-form-item>
            <el-form-item label="细胞悬液管数">
              <el-input v-model="exclusiveCareForm.cellSuspensionTube" placeholder="细胞悬液管数"></el-input>
            </el-form-item>
            <el-form-item label="采集时间" prop="cellSuspensionTime">
              <el-date-picker value-format="yyyy-MM-dd" v-model="exclusiveCareForm.cellSuspensionTime" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="输血史">
            <el-radio-group v-model="exclusiveCareForm.bloodTransfusionHistory">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">有</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item  v-show="exclusiveCareForm.bloodTransfusionHistory == 1"  label="输血类型">
            <el-radio-group v-model="exclusiveCareForm.bloodTransfusionType">
              <el-radio :label="1">红细胞</el-radio>
              <el-radio :label="2">血浆</el-radio>
              <el-radio :label="3">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="exclusiveCareForm.bloodTransfusionHistory == 1 &&  exclusiveCareForm.bloodTransfusionType == 3" label="输血其他">
              <el-input v-model="exclusiveCareForm.bloodTransfusionTypeName" placeholder="输血其他"></el-input>
            </el-form-item>
        <el-form-item label="输血时间" prop="bloodTransfusionTime">
              <el-date-picker value-format="yyyy-MM-dd" v-model="exclusiveCareForm.bloodTransfusionTime" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
            <div class="tipsTit">组织液样本信息</div>
        <el-form-item label="样本类型">
              <el-radio-group v-model="exclusiveCareForm.tissueSampleType">
                <el-radio :label="1">手术切除</el-radio>
                <el-radio :label="2">穿刺</el-radio>
                <el-radio :label="3">气管镜</el-radio>
                <el-radio :label="4">胃镜</el-radio>
                <el-radio :label="5">其他</el-radio>
              </el-radio-group>
          </el-form-item>
          <el-form-item  v-show="exclusiveCareForm.tissueSampleType == 5"  label="样本其他">
              <el-input v-model="exclusiveCareForm.tissueSampleTypeName" placeholder="样本其他"></el-input>
            </el-form-item>
            <el-form-item label="石蜡包埋组织">
            <el-radio-group v-model="exclusiveCareForm.paraffinEmbeddedTissueFlag">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">有</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="张数">
              <el-input v-model="exclusiveCareForm.paraffinEmbeddedNumber" placeholder="张数"></el-input>
            </el-form-item>
            <el-form-item label="厚度">
              <el-input v-model="exclusiveCareForm.paraffinEmbeddedTissueThickness" placeholder="厚度（um）"></el-input>
            </el-form-item>
            <el-form-item label="采集部位">
              <el-input v-model="exclusiveCareForm.paraffinEmbeddedTissuePosition" placeholder="采集部位"></el-input>
            </el-form-item>
            <el-form-item label="制备时间" prop="paraffinEmbeddedTissuePositionTime">
              <el-date-picker value-format="yyyy-MM-dd" v-model="exclusiveCareForm.paraffinEmbeddedTissuePositionTime" type="date" placeholder="选择制备时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="新鲜组织">
            <el-radio-group v-model="exclusiveCareForm.freshTissueFlag">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">有</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="组织（针）">
              <el-input v-model="exclusiveCareForm.freshTissueNeedle" placeholder="组织（针）"></el-input>
            </el-form-item>
            <el-form-item label="组织（管）">
              <el-input v-model="exclusiveCareForm.freshTissueTube" placeholder="组织（管）"></el-input>
            </el-form-item>
            <el-form-item label="是否冷冻">
            <el-radio-group v-model="exclusiveCareForm.freshTissueType">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">有</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="采集部位">
              <el-input v-model="exclusiveCareForm.freshTissuePosition" placeholder="采集部位"></el-input>
            </el-form-item>
            <el-form-item label="制备时间" prop="freshTissueTime">
              <el-date-picker value-format="yyyy-MM-dd" v-model="exclusiveCareForm.freshTissueTime" type="date" placeholder="选择制备时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="formain固定">
            <el-radio-group v-model="exclusiveCareForm.formainFlag">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">有</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="大小">
              <el-input v-model="exclusiveCareForm.formainSize" placeholder="大小：长*宽*高(cm)"></el-input>
            </el-form-item>
            <el-form-item label="采集部位">
              <el-input v-model="exclusiveCareForm.formainPosition" placeholder="采集部位"></el-input>
            </el-form-item>
            <el-form-item label="制备时间" prop="formainTime">
              <el-date-picker value-format="yyyy-MM-dd" v-model="exclusiveCareForm.formainTime" type="date" placeholder="选择制备时间"></el-date-picker>
            </el-form-item>

          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      header_button: true,
      showHeaderButton: true,
      disabled: true,
      showAddress: false,
      applyTypeData: [
        { id: 1, name: "住院绿通" },
        { id: 2, name: "门诊绿通" },
        { id: 3, name: "院中陪护" },
      ],
      greenStatusOptions: [
        { id: 0, name: "初审中" },
        { id: 1, name: "初审不通过" },
        { id: 2, name: "需求确认" },
        { id: 3, name: "预约中" },
        { id: 4, name: "预约成功" },
        { id: 6, name: "结案" },
        { id: 7, name: "受理中" },
      ],
      isExpertOptions: [
        { id: 1, name: "是" },
        { id: 0, name: "否" },
      ],
      //   护理天数
      applyNursingDaysOptions: [
        { id: "1", name: "一天(24小时)" },
        { id: "2", name: "二天" },
        { id: "3", name: "三天" },
        { id: "26", name: "26天（包月）" },
      ],
      examType: [],
      hydrothoraxAscitesType: [],
      exclusiveCareForm: {
        id: "",
        greenNo: "",
        type: "",
        applyHospitalStartDate: "",
        applyHospitalEndDate: "",
        applyHospital: "",
        applyDepartment: "",
        doctorName: "",
        greenStatus: "",
        illnessNote: "",
        selectedOptions: [],
      },
      rightCode: "",
      title: "",
      ticketId: "",
      selectedOptions: [],
      showMedicareCard: false,
      showIsExpert: false,
      showappealDesc: false,
      showillnessNote: false,
      showKaiDanDate: false,
      showdoctorName: false,
      showDate: false,
    };
  },
  computed: {
    dataRule() {
      return {
        applyHospitalStartDate: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        applyHospitalEndDate: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        selectedOptions: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
      };
    },
  },
  components: {},
  created() {},
  methods: {
    greenFun(params) {
      console.log("照护", params);
      this.exclusiveCareForm = {
        ...this.exclusiveCareForm,
        ...params.entityDetails,
      };
      if(params.entityDetails.examTissue == 1){
        this.examType.push('examTissue');
      }
      if(params.entityDetails.examBlood == 1){
        this.examType.push('examBlood');
      }
      if(params.entityDetails.ascitesFlag == 1){
        this.hydrothoraxAscitesType.push('ascitesFlag');
      }
      if(params.entityDetails.hydrothoraxFlag == 1){
        this.hydrothoraxAscitesType.push('hydrothoraxFlag');
      }
      this.ticketId = params.id;
      if (params.status === 2 || params.status === 0) {
        this.showHeaderButton = false;
      } else {
        this.showHeaderButton = true;
      }
      this.rightCode = params.rightCode;
      this.title = params.aliasRightName;
      this.getAreaList();
    },
    // 编辑
    editorApplicationInformation() {
      this.header_button = false;
      this.disabled = false;
      this.$emit("fatherMethod");
    },
    // 保存
    saveApplicationInformation() {
      if (!this.selectedOptions) {
        return this.$message.error("地区不能为空!");
      }
      this.$refs["exclusiveCareForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.exclusiveCareForm.ticketId = Number(this.ticketId);
        console.log(this.exclusiveCareForm);
        this.$http
          .post(`/tongshu/update`, this.exclusiveCareForm)
          .then(({ data: res }) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg);
            }
            this.$message({
              message: "保存成功",
              type: "success",
              duration: 500,
              onClose: () => {
                this.visible = false;
                this.header_button = true;
                this.disabled = true;
              },
            });
          })
          .catch(() => {});
      });
    }
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.basic_information {
  /* width: 90%; */
  /* border: 1px solid #cccccc; */
}
.input {
  display: inline-block;
}
.demo-form-inline {
  margin-left: 20px;
}
.el-form--inline .el-form-item {
  margin-right: 30px;
}
.tipsTit{
  width: 100%;
  font-size: 16px;
  line-height: 40px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 10px;
}
</style>

<template>
  <div>
    <div>
      <!-- <div>用户基本信息</div> -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>专属陪护信息</span>
          <span>
            <el-button type="text" style="float: right; padding: 3px 3px;" @click="queryUpdateStatus()">更新</el-button>
          </span>
        </div>
        <div class="headerTitle_text">
          <div class="bigtext">订单编号：{{ headerList.orderNo }}</div>
          <div>订单流水号：{{ headerList.tradeNo }}</div>
          <div>订单类型：{{ headerList.orderType == "NURSE" ? "护理订单" : "" }}</div>
          <div>
            订单状态：
            <!-- LOST_EFFICACY 已失效 -->
            <el-tag size="mini">{{
              headerList.status == "CREATED"
                ? "已创建"
                : headerList.status == "NO_PAY"
                ? "待付款"
                : headerList.status == "NO_USE"
                ? "待使用"
                : headerList.status == "ONGOING"
                ? "进行中"
                : headerList.status == "COMPLETED"
                ? "已完成"
                : headerList.status == "CANCEL"
                ? "已取消"
                : headerList.status == "REFUNDED"
                ? "已退款"
                : "已失效"
                
            }}</el-tag>
          </div>
          <div>合作伙伴编号：miaobao</div>
        </div>
        <div class="title">订单中所包含的产品</div>
        <div>
          <el-table :data="dataList1" border style="width: 100%;">
            <el-table-column prop="productName" label="产品名称" header-align="center" align="center"></el-table-column>
            <el-table-column prop="productCode" label="产品编号" header-align="center" align="center"></el-table-column>
            <el-table-column prop="productPlanCode" label="产品方案编号" header-align="center" align="center"></el-table-column>
            <el-table-column prop="productPrice" label="产品价格" header-align="center" align="center"></el-table-column>
            <el-table-column prop="productQuantity" label="产品数量" header-align="center" align="center"></el-table-column>
          </el-table>
        </div>
        <div class="title">工单信息</div>
        <div>
          <div class="orderTitle">
            <div class="orderTitle_text">
              <div>
                <span class="orderBigText">工单号码:</span> <span>{{ repairOrder.billNo }}</span>
              </div>
              <div>
                <span class="orderBigText">保单号:</span> <span>{{ repairOrder.policyNo }}</span>
              </div>
              <div>
                <span class="orderBigText">雇主身份证号:</span> <span>{{ repairOrder.employerIdNumber }}</span>
              </div>
              <div>
                <span class="orderBigText">雇主姓名:</span> <span>{{ repairOrder.employerName }}</span>
              </div>
              <div>
                <span class="orderBigText"> 雇主手机号:</span> <span>{{ repairOrder.employerMobile }}</span>
              </div>
              <div>
                <span class="orderBigText">雇主证件类型:</span>
                <span>{{ repairOrder.employerIdType == "OTHER" ? "其他" : repairOrder.employerIdType == "ID" ? "身份证" : "护照" }}</span>
              </div>
              <div>
                <span class="orderBigText">雇主性别:</span>
                <span>{{ repairOrder.employerSex == "MALE" ? "男" : "女" }}</span>
              </div>
              <div>
                <span class="orderBigText">患者姓名:</span> <span>{{ repairOrder.patientName }}</span>
              </div>
              <div>
                <span class="orderBigText">患者联系电话:</span> <span>{{ repairOrder.patientMobile }}</span>
              </div>
              <div>
                <span class="orderBigText">患者身份证号:</span>
                <span>{{ repairOrder.patientIdNumber }}</span>
              </div>
              <div>
                <span class="orderBigText">上门服务开始时间:</span>
                <span>{{ repairOrder.appointmentStartTime }}</span>
              </div>
            </div>
            <div class="orderTitle_text">
              <div>
                <span class="orderBigText">患者证件类型:</span>
                <span>{{ repairOrder.patientIdType == "OTHER" ? "其他" : repairOrder.patientIdType == "ID" ? "身份证" : "护照" }}</span>
              </div>
              <div>
                <span class="orderBigText">患者性别:</span>
                <span>{{ repairOrder.patientSex == "FEMALE" ? "女" : "男" }}</span>
              </div>
              <div>
                <span class="orderBigText">省份:</span> <span>{{ repairOrder.province }}</span>
              </div>
              <div>
                <span class="orderBigText">城市:</span> <span>{{ repairOrder.city }}</span>
              </div>
              <div>
                <span class="orderBigText">区域:</span> <span>{{ repairOrder.district }}</span>
              </div>
              <div>
                <span class="orderBigText">服务详细地址:</span>
                <span>{{ repairOrder.serviceAddressDetail }}</span>
              </div>
              <div>
                <span class="orderBigText"> 服务周期:</span> <span>{{ repairOrder.serviceCycle }}</span>
              </div>
              <div>
                <span class="orderBigText"> 工单状态:</span>
                <span>{{
                  repairOrder.status == "CREATED"
                    ? "已创建"
                    : repairOrder.status == "NO_RESERVE"
                    ? "待评估"
                    : repairOrder.status == "RESERVE"
                    ? "评估中"
                    : repairOrder.status == "NO_SERVICE"
                    ? "待服务"
                    : repairOrder.status == "IN_SERVICE"
                    ? "服务中"
                    : repairOrder.status == "NO_EVALUATE"
                    ? "待评价"
                    : repairOrder.status == "EVALUATED"
                    ? "已评价"
                    : repairOrder.status == "EXCEPTION"
                    ? "异常"
                    : "关闭"
                }}</span>
              </div>
              <div>
                <span class="orderBigText">工单结束时间:</span> <span>{{ repairOrder.doneTime }}</span>
              </div>
              <div>
                <span class="orderBigText"> 患者与雇主关系:</span>
                <span>{{
                  repairOrder.relationship == "RELATIVES"
                    ? "亲属"
                    : repairOrder.relationship == "FRIEND"
                    ? "朋友"
                    : repairOrder.relationship == "COLLEAGUE"
                    ? "同事"
                    : repairOrder.relationship == "OTHER"
                    ? "其他"
                    : ""
                }}</span>
              </div>
              <div>
                <span class="orderBigText">上门服务结束时间:</span>
                <span>{{ repairOrder.appointmentEndTime }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="title">服务工单信息</div>
        <div>
          <el-table :data="dataList2" border style="width: 100%;">
            <el-table-column prop="billType" label="工单类型" header-align="center" align="center">
              <template slot-scope="scope">
                {{ $getDictLabel("bigBillType", scope.row.billType) }}
                <!-- <el-tab v-if="scope.row.billType == 'ASSESS'">评估工单</el-tab> -->
              </template>
            </el-table-column>
            <el-table-column prop="serviceUserName" label="服务人员姓名" header-align="center" align="center"></el-table-column>
            <el-table-column prop="serviceUserMobile" label="服务人员联系电话" header-align="center" align="center"></el-table-column>
            <el-table-column prop="userReceiptTime" label="服务人员接单时间" header-align="center" align="center" :formatter="formatDate"></el-table-column>
            <el-table-column prop="doneTime" label="工单结束时间" header-align="center" align="center" :formatter="formatDate">
              <!-- <template slot-scope="scope">
                {{ getDoneTime(scope.row.doneTime) }}
              </template> -->
            </el-table-column>
            <el-table-column prop="status" label="工单服务状态" header-align="center" align="center">
              <template slot-scope="scope">
                {{ $getDictLabel("bigStatus", scope.row.status) }}
              </template>
            </el-table-column>
            <el-table-column prop="report" label="评估报告" header-align="center" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="lookReport(scope.row)">查看报告</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="reportTime" label="评估时间" header-align="center" align="center"></el-table-column>
            <el-table-column prop="userResponseTime" label="服务人员响应时间" header-align="center" align="center">
              <template slot-scope="scope">
                {{ getUserResponseTime(scope.row.userResponseTime) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      dataForm: {},
      headerList: {},
      repairOrder: {},
      index: 0,
      dataList1: [],
      dataList2: [],
      greenId: "",
      rightId: "",
    };
  },
  mounted() {},
  methods: {
    updates() {
      // console.log("护理");
      Object.assign(this.$data, this.$options.data());
    },
    greenFuns(params) {
      // console.log('专属陪护all', JSON.parse(params.entityDetails));
      console.log("专属陪护ceshi", params);
      let headerList = JSON.parse(params.entityDetails.chaperonageInfo);
      this.greenId = params.entityId;
      this.rightId = params.rightId;
      this.headerList = headerList;
      // ===
      console.log("专属陪护headerList", headerList);
      let repairOrder = headerList.orderBill;
      this.repairOrder = repairOrder;
      // ===
      this.dataList2 = headerList.billItemArray;
      // ===
      let dataList = headerList.orderItem;
      let dataList1 = [];
      dataList1.push(dataList);
      this.dataList1 = dataList1;
    },
    queryUpdateStatus() {
      let params = {
        id: this.greenId,
        rightId: this.rightId,
      };
      this.$http
        .post(`/healthbusiness/health-green-channel/selectBigTreeOrderInfo`, params)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          // console.log(res);
          let data = JSON.parse(res.data);
          console.log("ceshi", JSON.parse(res.data));
          let headerList = JSON.parse(data.data);
          this.headerList = headerList;
          //  ===
          let repairOrder = headerList.orderBill;
          this.repairOrder = repairOrder;
          // ===
          this.dataList2 = headerList.billItemArray;
          // ===
          let dataList = headerList.orderItem;
          let dataList1 = [];
          dataList1.push(dataList);
          this.dataList1 = dataList1;
          // console.log('sdsfasdfasdfasdfasdfasdfasdfs', headerList);
          this.$message({
            message: this.$t("prompt.success"),
            type: "success",
            duration: 500,
            onClose: () => {},
          });
        })
        .catch(() => {});
    },
    formatDate(row, column) {
      var date = row[column.property];
      // console.log('date', date);
      if (date == undefined || date == "" || date == null) {
        return "";
      }
      date = parseInt(date);
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    lookReport(row) {
      console.log(row);
      if (row.report == "" || row.report == undefined || row.report == null) {
        this.$message({
          message: "暂无报告可查看！",
          type: "warning",
        });
      } else {
        // window.location.href = row.report;
        window.open(row.report);
      }
    },
    getUserResponseTime(row) {
      let date = "";
      if (row == "" || row == null || row == undefined) {
        date = "";
      } else {
        date = row + "秒";
      }
      return date;
    },
    // getDoneTime(e) {
    //   console.log('dddddddddddddddd', e);
    //   console.log(moment.unix(e).format('YYYY-MM-DD HH:mm:ss'));
    // },
  },
};
</script>
<style>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.title {
  margin-top: 40px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  border-left: 5px solid rgba(3, 165, 151, 0.616);
  padding-left: 5px;
}
.orderTitle {
  display: flex;
  justify-content: space-around;
}
.orderTitle .orderTitle_text div {
  margin-top: 10px;
  /* text-align: right; */
}
.headerTitle_text {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.headerTitle_text div {
  margin-top: 10px;
}
.orderBigText {
  display: inline-block;
  width: 120px;
  text-align: right;
  margin-right: 20px;
}
/* .el-table th {
  color: rgb(112, 110, 110);
} */
</style>

<template>
  <div>
    <div>
      <el-card>
        <div slot="header" class="clearfix">
          <span>服务日志</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            @click="openDialogFormVisible"
            >新增</el-button
          >
          <!-- dialogFormVisible -->
        </div>
        <div>
          <el-table :data="dataList" border style="width: 100%">
            <el-table-column
              prop="createDate"
              label="时间"
              width="100%"
              header-align="center"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="id"
              label="操作人"
              width="100%"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                {{ getOperation(scope.row.sysUserEntity) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="ticketsStatus"
              width="100%"
              label="工单状态"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag>
                  {{ getworkOrderStatus(scope.row.ticketsStatus) }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="note"
              label="内容"
              header-align="center"
              align="center"
            ></el-table-column>
          </el-table>
        </div>
        <div class="dialog">
          <el-dialog
            title="备注"
            :visible.sync="dialogFormVisible"
            label-position="left"
            width="80%"
          >
            <el-form
              :model="dataForm"
              class="demo-form-inline"
              style="width: 100%"
            >
              <el-form-item label="内容" label-width="50px">
                <el-input
                  v-model="dataForm.note"
                  type="textarea"
                  placeholder="内容"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="closeDialog">取 消</el-button>
              <el-button type="primary" @click="addLog()">确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      dataList: [],
      workOrderstatus: '',
      dataForm: {
        note: '',
      },
      rightCode: '',
      rightName: '',
      ticketsId: '',
      ticketsStatus: '',
    };
  },
  created() {
    // this.getclaimsBasicInformation();
    // this.getLog();
  },
  mounted() {
    // this.getclaimsBasicInformation();
    // this.getLog();
  },
  methods: {
    updates() {
      console.log('日志');
      Object.assign(this.$data, this.$options.data());
    },
    greenFunservelog(params) {
      // console.log('我的', params);
      console.log('我的', params.rightCode);
      let _this = this;
      _this.rightCode = params.rightCode;
      _this.rightName = params.rightName;
      _this.ticketsStatus = params.status;
      _this.ticketsId = params.id;
      _this.workOrderstatus = params.status;
      _this.dataList = params.healthServiceLogList;
      // console.log('我的', _this.dataList);
    },
    openDialogFormVisible() {
      // Object.assign(this.$data, this.$options.data());
      this.dialogFormVisible = true;
      this.dataForm.note = '';
    },
    closeDialog() {
      this.dialogFormVisible = false;
      this.dataForm.note = '';
    },
    // getclaimsBasicInformation() {
    //   this.$http
    //     .get(`/api/micsgreenchannel/getGreenChannelDetail`, {
    //       params: { id: this.$route.query.id },
    //     })
    //     .then(({ data: res }) => {
    //       if (res.code !== 0) {
    //         return this.$message.error(res.msg);
    //       }
    //       console.log('resworkOrderstatus', res);
    //       this.workOrderstatus = res.data.greenStatus;
    //     })
    //     .catch(() => {});
    // },
    getLog() {
      this.$http
        .get(`/api/micsservicelog/page`, {
          params: { workOrderType: 3, workOrderId: this.$route.query.id },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.dataList = res.data.list;
          // console.log('服务日志res', res);
          // console.log('参数', serviceObj)
        })
        .catch(() => {});
    },
    addLog() {
      let serviceObj = {
        ticketsId: this.ticketsId,
        rightCode: this.rightCode,
        rightName: this.rightName,
        ticketsStatus: this.ticketsStatus,
        note: this.dataForm.note,
      };
      this.$http
        .post(`/healthbusiness/health-service-log`, serviceObj)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.dialogFormVisible = false;
              this.$emit('fatherMethod');
              this.dataForm.note = '';
              // this.getLog();
            },
          });
          // console.log('服务日志res', res);
        })
        .catch(() => {});
    },
    getOperation(row) {
      let realName = '';
      if (row === null || row === undefined || row === '') {
        realName = '';
      } else {
        realName = row.realName;
      }
      return realName;
    },
    getworkOrderStatus(row) {
      let workOrderStatus = '';
      if (row === null || row === undefined || row === '') {
        workOrderStatus = '';
      } else {
        if (row === 0) {
          workOrderStatus = '已完成';
        } else if (row === 0) {
          workOrderStatus = '已取消';
        } else if (row === 1) {
          workOrderStatus = '进行中';
        }
      }
      return workOrderStatus;
    },
  },
};
</script>
<style>
.el-form-item__content /deep/ .el-input__inner {
  width: 100%;
}
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
/* /deep/.dialog .el-dialog__wrapper {
  z-index: 1000 !important;
} */
.v-modal {
  z-index: 1000 !important;
}
.el-dialog {
  z-index: 2000;
}
</style>

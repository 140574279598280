<template>
  <div class="mod-green_basic_information}">
    <div>
      <!-- <div>用户基本信息</div> -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>用户基本信息</span>
          <span v-if="showHeaderButton">
            <el-button
              type="text"
              style="float: right; padding: 3px 3px;"
              v-if="$hasPermission('miaoins')"
              @click="editorGreenBasicInformation()"
              >编辑</el-button
            >
            <el-button
              type="text"
              style="float: right; padding: 3px 3px;"
              v-if="$hasPermission('miaoins')"
              @click="saveGreenBasicInformation()"
              >保存</el-button
            >
          </span>
          <!-- <span>{{ greenss }}</span> -->
        </div>
        <div class="basic_information">
          <el-form
            :inline="true"
            :model="greenBasicInformation"
            :disabled="disabled"
            ref="greenBasicInformation"
            :rules="dataRule"
            class="demo-form-inline"
          >
            <el-form-item label="用户ID">
              <el-input
                v-model="greenBasicInformation.personId"
                readonly
                clearable
                placeholder="用户姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户姓名" prop="name">
              <el-input
                v-model="greenBasicInformation.name"
                clearable
                placeholder="用户姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="证件号码" prop="certificateNo">
              <el-input
                v-model="greenBasicInformation.certificateNo"
                placeholder="证件号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="phone">
              <el-input
                v-model="greenBasicInformation.phone"
                placeholder="手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="民族" prop="phone">
              <el-input
                v-model="greenBasicInformation.nation"
                placeholder="民族"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮寄地区" prop="showAddress">
              <el-cascader v-model="greenBasicInformation.showAddress" :options="options" :props="{ expandTrigger: 'hover' }" clearable @change="handleChange"></el-cascader>
            </el-form-item> 
            <el-form-item label="详细地址" prop="phone">
              <el-input
                v-model="greenBasicInformation.address"
                placeholder="详细地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="收件人" prop="phone">
              <el-input
                v-model="greenBasicInformation.postName"
                placeholder="收件人"
              ></el-input>
            </el-form-item>
            <el-form-item label="收件人联系电话" prop="phone">
              <el-input
                v-model="greenBasicInformation.postPhone"
                placeholder="收件人联系电话"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { treeDataTranslate } from "@/utils";
export default {
  props: ["green"],
  data() {
    return {
      header_button: true,
      showHeaderButton: true,
      disabled: true,
      SexData: [
        { id: 0, name: "男" },
        { id: 1, name: "女" },
      ],
      IdTypeData: [
        { id: 1, name: "身份证" },
        { id: 2, name: "台湾居民往来大陆证件" },
        { id: 3, name: "港澳居民往来大陆证件" },
        { id: 4, name: "护照" },
      ],
      options: [],
      greenBasicInformation: {
        personId: "",
        name: "",
        certificateNo: "",
        phone: "",
        ticketId: "",
        nation:'',
        postName:'',
        postPhone: '',
        provinceName:'',
        cityName:'',
        areaName:'',
        address:'',
      },
      ticketId: "",
    };
  },
  // computed: {
  //   greenss: function() {
  //     console.log('dd', this.green);
  //   },
  // },
  components: {
    // AddOrUpdate
  },
  computed: {
    dataRule() {
      return {
        name: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        certificateNo: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
          {
            pattern: /^1(3|4|5|7|8)\d{9}$/,
            message: "手机号码格式不对，请重新输入",
            trigger: "blur",
          },
        ],
      };
    },
  },
  created() {
    this.getAreaList();
  },
  methods: {
    // 父组件传参
    greenFun(params) {
      // console.log('params.status', );
      this.greenBasicInformation = {
        ...this.greenBasicInformation,
        ...params.entityDetails,
      };
      let regionData = [];
      regionData.push(params.entityDetails.provinceName);
      regionData.push(params.entityDetails.cityName);
      regionData.push(params.entityDetails.areaName);
      console.log(regionData);
      this.greenBasicInformation.showAddress = regionData;
      this.ticketId = params.id;
      if (params.status === 2 || params.status === 0) {
        this.showHeaderButton = false;
      } else {
        this.showHeaderButton = true;
      }
      this.$store.commit("entityId", params.entityDetails.id);
    },
    editorGreenBasicInformation() {
      this.header_button = false;
      this.disabled = false;
      this.$emit("fatherMethod");
    },
    getAreaList() {
      this.$http
        .get(`/sys/region/tree`)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          let dataList = res.data;
          for (let i in dataList) {
            dataList[i].value = dataList[i].id;
            dataList[i].label = dataList[i].name;
          }

          let dataList2 = treeDataTranslate(dataList);
          this.options = dataList2;
        })
        .catch(() => {});
    },
    saveGreenBasicInformation() {
      this.$refs["greenBasicInformation"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.greenBasicInformation.ticketId = Number(this.ticketId);
        this.$http.post( `/tongshu/update`, this.greenBasicInformation)
          .then(({ data: res }) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg);
            }
            this.$message({
              message: "保存成功",
              type: "success",
              duration: 500,
              onClose: () => {
                this.visible = false;
                this.header_button = true;
                this.disabled = true;
                // this.getGreenBasicInformation();
              },
            });
          })
          .catch(() => {});
      });
    },
    handleChange(e) {
      // console.log(this.selectedOptions);
      this.greenBasicInformation.provinceName = e[0];
      this.greenBasicInformation.cityName = e[1];
      this.greenBasicInformation.areaName = e[2];
    },
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.basic_information {
  /* width: 90%; */
  /* border: 1px solid #cccccc; */
}
.input {
  display: inline-block;
}
.demo-form-inline {
  margin-left: 20px;
}
.el-form--inline .el-form-item {
  margin-right: 30px;
}
</style>
